// preambel
import 'core-js/es/reflect';
import 'core-js/stable/reflect';
import 'core-js/features/reflect';
import 'core-js/proposals/reflect-metadata';
import 'zone.js';
import '@angular/compiler';
// other
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createInstance, Piral } from 'piral-core';
import { Loading } from './components/Loading';
import { getPlugins } from './plugins';
import { components } from './components/Layout';
import { errorComponents } from './components/Errors';
import { Dashboard } from 'piral-dashboard';
import { storage } from 'piral-core';
import { KeycloakProvider, useKeycloak } from './keycloak-api';

import('preline');

const AuthorizedApp = () => {
  const { keycloak, loading } = useKeycloak();
  if (loading) {
    return <Loading />;
  }

  // Sprache kommt von keycloak und wird einmalig gesetzt
  let locale: string = keycloak.idTokenParsed?.['locale'];
  if (!locale) {
    locale = navigator.language ?? 'de';
    if (locale.indexOf('-') > 0) {
      locale = locale.substring(0, locale.indexOf('-'));
    }
  }
  storage.setItem('locale', locale);

  const appInstance = createInstance({
    plugins: getPlugins(),
    requestPilets: () => {
      return fetch('/feed', {
        headers: {
          Authorization: `Bearer ${keycloak.token}`,
        },
      })
        .then(res => res.json())
        .then(res => res.items);
    },
    state: {
      components,
      errorComponents,
      routes: {
        '/': Dashboard,
      },
    },
  });
  (window as any).keycloakGetIdToken = () => {
    return JSON.stringify(keycloak.idTokenParsed);
  };

  (window as any).keycloakGetAccessToken = () => {
    return keycloak.token;
  };

  return <Piral instance={appInstance} />;
};

const App = () => {
  return (
    <KeycloakProvider>
      <React.Suspense fallback={<Loading />}>
        <AuthorizedApp />
      </React.Suspense>
    </KeycloakProvider>
  );
};

const root = createRoot(document.querySelector('#app'));
root.render(<App />);
