import Keycloak from 'keycloak-js';
import React, { createContext, useContext, useEffect, useMemo, useRef, useReducer, useCallback } from 'react';
import { initOptions, keycloakClient } from './client';

type State = {
  keycloak: Keycloak | undefined;
  authenticated: boolean;
  loading: boolean;
};

type Action = { type: 'SET_KEYCLOAK'; payload: Keycloak } | { type: 'SET_AUTHENTICATED'; payload: boolean } | { type: 'SET_LOADING'; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_KEYCLOAK':
      return { ...state, keycloak: action.payload };
    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action.payload };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

const initialContextValue = {
  keycloak: undefined,
  authenticated: false,
  loading: true,
};

const KeycloakContext = createContext<State>(initialContextValue);

export const KeycloakProvider = ({ children }) => {
  const didLogRef = useRef(false);
  const [state, dispatch] = useReducer(reducer, initialContextValue);

  const initializeKeycloak = useCallback(() => {
    keycloakClient
      .init(initOptions)
      .then(authenticated => {
        dispatch({ type: 'SET_KEYCLOAK', payload: keycloakClient });
        dispatch({ type: 'SET_AUTHENTICATED', payload: authenticated });
      })
      .catch(error => {
        console.error('Failed to initialize Keycloak:', error);
      })
      .finally(() => {
        dispatch({ type: 'SET_LOADING', payload: false });
      });
  }, []);

  useEffect(() => {
    if (!didLogRef.current) {
      didLogRef.current = true;
      initializeKeycloak();
    }
  }, [initializeKeycloak]);

  const contextValue = useMemo(() => state, [state]);

  return <KeycloakContext.Provider value={contextValue}>{children}</KeycloakContext.Provider>;
};

export function useKeycloak() {
  const context = useContext(KeycloakContext);

  if (!context) {
    throw new Error('useKeycloak must be used within a KeycloakProvider');
  }
  return context;
}
