import { createBreadcrumbsApi } from 'piral-breadcrumbs';
import { createDashboardApi } from 'piral-dashboard';
import { createMenuApi } from 'piral-menu';
import { createNotificationsApi } from 'piral-notifications';
import { createSnowfallApi } from './snowfall-api';
import { PiralPlugin, storage } from 'piral-core';
import { createNgApi } from 'piral-ng';
import { createSipletApi } from './siplet-api';
import { translations } from './translations.codegen';
import { LocaleConfig, createLocaleApi, setupLocalizer } from 'piral-translate';
import { createKeycloakApi } from './keycloak-api';

function setupLocalizable() {
  const locale = storage.getItem('locale');
  const config: LocaleConfig = {
    language: locale,
    messages: translations,
    fallback: (key, language) => {
      console.warn(`Translation for '${key}' in ${language.toUpperCase()} missing.`);
      const message = (config.messages['en'][key] as string) ?? key;
      return message;
    },
  };
  return setupLocalizer(config);
}

export function getPlugins(): Array<PiralPlugin> {
  return [
    createMenuApi({
      // defaultSettings gilt für alle Pilets, die keine expliziten MenuSettings haben
      defaultSettings: {
        type: 'sidebar',
      },
    }),
    createNotificationsApi(),
    createDashboardApi({
      defaultPreferences: {
        initialColumns: 2,
        initialRows: 2,
      },
    }),
    createLocaleApi(setupLocalizable()),
    createBreadcrumbsApi(),
    createSnowfallApi(),
    createKeycloakApi(),
    createNgApi(),
    createSipletApi(),
  ];
}
