import React from 'react';
import { Link } from 'react-router-dom';

export const SipTile = ({ linkTo, title, description, imgSource }) => {
  return (
    <Link to={linkTo} className="p-4 md:p-5 no-underline">
      <div className="flex">
        <img src={imgSource} className="mt-1 shrink-0 w-10 h-10 text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200 dark:invert" alt="icon" />
        <div className="wrap ml-5">
          <h3 className="group-hover:text-blue-600 font-semibold text-gray-800 dark:group-hover:text-gray-400 dark:text-gray-200">{title}</h3>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </Link>
  );
};
