import React from 'react';
import { SipLogo } from './SipLogo';
import { SnowfallButton } from '../snowfall-api';
import { Breadcrumbs } from 'piral-breadcrumbs';
import { LoginButton } from './LoginButton';
import { ExtensionSlot } from 'piral-core';
import { TestButtons } from './TestButtons';
import { ThemeSwitchButton } from './ThemeSwitchButton';
import { ProfileButton } from './ProfileButton';
import { useKeycloak } from '../keycloak-api';

export const Header = () => {
  const { keycloak } = useKeycloak();
  return (
    <header className="fixed top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-[48] w-full bg-white border-b text-sm py-2.5 sm:py-4 lg:pl-64 dark:bg-vscode-bg dark:border-vscode-border">
      <nav className="flex basis-full items-center w-full mx-auto px-4 sm:px-6 md:px-8" aria-label="Global">
        <div className="mr-5 lg:mr-0 lg:hidden" id="logo-in-header">
          <SipLogo isAuthenticated={keycloak.authenticated} />
        </div>

        <div className="w-full flex items-center justify-end ml-auto lg:justify-between sm:gap-x-3 sm:order-3">
          <div className="hidden lg:block">
            <Breadcrumbs />
          </div>

          <div className="flex flex-row items-center justify-end gap-2">
            {!keycloak.authenticated && (
              <>
                <LoginButton keycloak={keycloak} />
              </>
            )}
            {keycloak.authenticated && (
              <>
                <TestButtons />
                <ThemeSwitchButton />
                <SnowfallButton />
                <ExtensionSlot name="notifications" />
                <ProfileButton keycloak={keycloak} />
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};
