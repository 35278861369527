
    export const translations = {
      "de":{
  "abmelden": "Abmelden",
  "anmelden": "Anmelden",
  "angemeldet-als": "Angemeldet als",
  "error": "Fehler",
  "seite-existiert-nicht-text": "Die angeforderte Seite wurde nicht gefunden. Bist du sicher, dass sie existiert?",
  "stufe": "Stufe",
  "zur-startseite": "Zur Startseite",
  "feed-server-offline": "Der Feed-Server ist nicht erreichbar.",
  "bitte-versuche-es-spaeter-nochmal": "Bitte versuche es später nochmal.",
  "account-verwalten": "Account verwalten",
  "dark-mode": "Dunkel",
  "light-mode": "Hell",
  "apps": "Apps",
  "impressum": "Impressum",
  "impressum-link": "https://salamander-windows.com/rechtliches/impressum"
}
,"en":{
  "abmelden": "Logout",
  "account-verwalten": "Manage account",
  "angemeldet-als": "Logged in as",
  "anmelden": "Login",
  "apps": "Apps",
  "bitte-versuche-es-spaeter-nochmal": "Please try again later.",
  "dark-mode": "Dark",
  "error": "Error",
  "feed-server-offline": "The feed server cannot be reached.",
  "light-mode": "Light",
  "seite-existiert-nicht-text": "Could not find the requested page. Are you sure it exists?",
  "stufe": "Level",
  "zur-startseite": "Back to start page",
  "impressum": "imprint",
  "impressum-link": "https://salamander-windows.com/legal/imprint"
}
,"es":{
  "abmelden": "Cerrar sesión",
  "anmelden": "Iniciar sesión",
  "angemeldet-als": "Conectado como",
  "error": "Errorinio",
  "seite-existiert-nicht-text": "No se pudo encontrar la página solicitada. ¿Estás seguro de que existe?",
  "stufe": "Nivel",
  "zur-startseite": "Volver a la página de inicio",
  "account-verwalten": "Administrar cuenta",
  "dark-mode": "Modo oscuro",
  "light-mode": "Modo claro",
  "apps": "Aplicaciones",
  "bitte-versuche-es-spaeter-nochmal": "Por favor, inténtelo de nuevo más tarde.",
  "feed-server-offline": "No se puede acceder al servidor de feeds.",
  "impressum": "Aviso legal",
  "impressum-link": "https://salamander-windows.com/rechtliches/impressum"
}
,"pl":{
  "abmelden": "Wyloguj",
  "account-verwalten": "Zarządzaj kontem",
  "angemeldet-als": "Zalogowany jako",
  "anmelden": "Zaloguj",
  "apps": "Aplikacje",
  "bitte-versuche-es-spaeter-nochmal": "Spróbuj ponownie później.",
  "dark-mode": "Ciemny",
  "error": "Błąd",
  "feed-server-offline": "Nie można uzyskać dostępu do serwera kanałów informacyjnych.",
  "light-mode": "Jasny",
  "seite-existiert-nicht-text": "Żądana strona nie została znaleziona. Jesteś pewien/pewna, że istnieje?",
  "stufe": "Poziom",
  "zur-startseite": "Do strony głównej",
  "impressum": "Impressum",
  "impressum-link": "https://salamander-windows.com/prawne/impressum"
}

    }
  