import { PiralPlugin } from 'piral-core';
import { PiralKeycloakApi } from './types';
import { keycloakClient } from './client';

/**
 * Creates new Pilet API extensions for enabling keycloak support.
 */
export function createKeycloakApi(): PiralPlugin<PiralKeycloakApi> {
  return context => {
    context.dispatch(state => ({
      ...state,
      keycloak: keycloakClient,
    }));

    return {
      getKeycloakClient() {
        return context.readState(s => s.keycloak);
      },
    };
  };
}
