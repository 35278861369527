import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SipMenuItemProps } from './types';
import { useLocation } from 'react-router';

export const SipMenuItem: React.FC<SipMenuItemProps> = ({ item }) => {
  if (item.subItems) {
    return <SipMenuAccordeonItem route={item.route} icon={item.icon} title={item.title} subItems={item.subItems} />;
  } else {
    return <SipMenuLinkItem route={item.route} icon={item.icon} title={item.title} badge={item.badge} tooltip={item.tooltip} />;
  }
};

const SipMenuLinkItem = ({ route, icon, title, badge, tooltip }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (route === '/') {
      setIsActive(false);
    } else {
      if (location.pathname === route) {
        setIsActive(true);
      } else {
        setIsActive(location.pathname.startsWith(route + '/'));
      }
    }
  }, [location.pathname, route]);

  const hasTooltip = !!tooltip;
  let baseState = 'flex items-center gap-x-3.5 py-2 px-2.5 text-slate-700 rounded-md relative no-underline';
  if (hasTooltip) {
    baseState += ' hs-tooltip-toggle';
  }
  const defaultState = baseState + ' hover:bg-gray-100 hover:dark:bg-gray-700 dark:bg-vscode-card dark:text-white';
  const activeState = baseState + ' bg-gray-200 hover:bg-gray-300 hover:dark:bg-gray-900 dark:bg-gray-900 dark:text-white';

  if (hasTooltip) {
    return (
      <li>
        <div className="hs-tooltip inline-block [--placement:right]  w-full">
          <Link id={title} to={route} className={isActive ? activeState : defaultState}>
            <img className="w-4 h-4 dark:invert" src={icon} alt="icon" />

            <div className="w-full flex justify-between items-center">
              <p className="text-base text-slate-700 dark:text-white">{title}</p>
              {badge && <span className="rounded-full text-xs font-medium gap-0.75 py-0.75 px-1.5 bg-red-500 text-white">{badge}</span>}
            </div>
            {tooltip && (
              <span
                className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-slate-700"
                role="tooltip">
                {tooltip}
              </span>
            )}
          </Link>
        </div>
      </li>
    );
  } else {
    return (
      <li className="w-full">
        <Link id={title} to={route} className={isActive ? activeState : defaultState}>
          <img className="w-4 h-4 dark:invert" src={icon} alt="icon" />
          <div className="w-full flex justify-between items-center">
            <p className="text-base text-slate-700 dark:text-white">{title}</p>
            {badge && <span className="rounded-full text-xs font-medium gap-0.75 py-0.75 px-1.5 bg-red-500 text-white">{badge}</span>}
          </div>
        </Link>
      </li>
    );
  }
};

const SipMenuAccordeonItem = ({ route, icon, title, subItems }) => {
  const routeId = String(route).replaceAll('/', '').replaceAll('#', '').replaceAll('-', '');
  return (
    <li className="hs-accordion" id={'accordion-for-' + routeId}>
      <Link
        to="#"
        className="hs-accordion-toggle flex items-center gap-x-3.5 py-2 px-2.5 hs-accordion-active:text-blue-600 text-slate-700 rounded-md hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-900 dark:text-slate-400 dark:hover:text-slate-300 dark:hs-accordion-active:text-white no-underline"
        aria-controls={'accordion-collapse-for-' + routeId}>
        <img className="w-4 h-4 dark:invert" src={icon} alt="icon" />
        <p className="text-base text-slate-700 dark:text-white">{title}</p>
        <svg
          className="hs-accordion-active:block ml-auto hidden w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 11L8.16086 5.31305C8.35239 5.13625 8.64761 5.13625 8.83914 5.31305L15 11"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"></path>
        </svg>

        <svg
          className="hs-accordion-active:hidden ml-auto block w-3 h-3 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"></path>
        </svg>
      </Link>

      <div
        id={'accordion-collapse-for-' + routeId}
        className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300 hidden"
        aria-labelledby={'accordion-for-' + routeId}>
        <ul className="pt-2 pl-2">{subItems && subItems.map((sub, index) => <SipMenuLinkItem key={title + '-sub-' + index} {...sub} />)}</ul>
      </div>
    </li>
  );
};
