import React from 'react';
import { SipLogo } from './SipLogo';
import { MenuRootItem, SipMenuItem } from '../siplet-api';
import { PiletOnlyMenu } from './PiletOnlyMenu';
import { VersionInfo } from './VersionInfo';
import { ExtensionSlot } from 'piral-core';
import { useTranslate } from 'piral-translate';
import { useKeycloak } from '../keycloak-api';

export const Sidebar = () => {
  const translate = useTranslate();
  const { authenticated } = useKeycloak();
  const dashboardItem: MenuRootItem = {
    route: '/',
    icon: require('../assets/svgs/home.svg'),
    title: translate('apps'),
  };
  return (
    <div
      id="application-sidebar"
      className="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 left-0 bottom-0 z-[60] w-64 bg-white border-r border-gray-200 lg:block lg:translate-x-0 dark:bg-vscode-bg dark:border-vscode-border">
      <div className="overflow-y-auto absolute top-0 left-0 bottom-0 right-0 pt-7 pb-10 lurchi-background scrollbar-hide">
        <div className="px-6" id="logo-in-sidebar">
          <SipLogo isAuthenticated={authenticated} />
        </div>

        <nav className="hs-accordion-group p-6 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
          <ul className="space-y-1.5">
            {authenticated && <SipMenuItem item={dashboardItem} />}
            <PiletOnlyMenu type="sidebar" />
          </ul>
        </nav>
      </div>
      <div className="absolute bottom-0 left-0 right-0 pl-1 pr-1 pt-1 bg-white lurchi-background">
        <ExtensionSlot name="bottom-sidebar" />
        <VersionInfo />
        <div className="mb-2">
          <a href={translate('impressum-link')} target="_blank">
            {translate('impressum')}
          </a>
        </div>
      </div>
    </div>
  );
};
