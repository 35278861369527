import Keycloak, { KeycloakInitOptions } from 'keycloak-js';

export const keycloakClient = new Keycloak({
  url: 'https://login.salamander.network',
  realm: 'apps',
  clientId: 'appcenter-frontend',
});

export const initOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: false,
};
